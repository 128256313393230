import React from "react"
import { Link } from "gatsby"
import styles from "./links.module.css"

export default function Links({ type }) {
  return (
    <div className={styles.links}>
      <Link
        to="/services/immigration"
        className={styles.link}
        activeClassName={styles.active}
      >
        Immigration
      </Link>

      <Link
        to="/services/criminal-law"
        className={styles.link}
        activeClassName={styles.active}
      >
        Criminal Law
      </Link>

      <Link
        to="/services/family-law"
        className={styles.link}
        activeClassName={styles.active}
      >
        Family Law
      </Link>

      <Link
        to="/services/personal-injury"
        className={styles.link}
        activeClassName={styles.active}
      >
        Personal Injury
      </Link>

      <Link
        to="/services/civil-litigation"
        className={styles.link}
        activeClassName={styles.active}
      >
        Civil Litigation
      </Link>
      <Link
        to="/services/wills-in-islam"
        className={styles.link}
        activeClassName={styles.active}
      >
        Wills in Islam
      </Link>
    </div>
  )
}
