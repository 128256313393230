import React from "react"
import styles from "./banner.module.css"

export default function Banner({ image, title }) {
  return (
    <div className={styles.banner}>
      {image}
      <div className={styles.overlay}>
        <h1>{title}</h1>
      </div>
    </div>
  )
}
